<template>
  <div>
    <div class="pagination-group" v-if="pagination">
      <img class="margin-right-16 margin-top-1 unselect"
           :class="{'svg-gray':filtered.cur_page===1,
           'svg-sub2':filtered.cur_page!==1}"
           src="/static/icon/fi_chevron-left-double.svg"
           style="width:20px;height:20px"
           @click="startPage"/>
      <img class="margin-right-16 margin-top-1 unselect"
           :class="{'svg-gray':filtered.cur_page===1,
           'svg-sub2':filtered.cur_page!==1}"
           src="/static/icon/fi_chevron-left.svg"
           style="width:20px;height:20px"
           @click="prevPage"/>
      <div class="pagination-num" v-for="(n,idx) in calcPageList" :key="'page-'+idx">
        <span
            class="unselect size-16"
            :class="{
            'pagination-num-selected': n === filtered.cur_page,
            'pagination-num-not-selected': n !== filtered.cur_page,
          }"
            @click="changePage(n)">{{ n }}</span>
      </div>
      <img class="margin-left-16 margin-top-1 unselect"
           :class="{'svg-gray':filtered.total_page===filtered.cur_page,
           'svg-sub2':filtered.total_page!==filtered.cur_page}"
           src="/static/icon/fi_chevron-right.svg"
           style="width:20px;height:20px"
           @click="nextPage"/>
      <img class="margin-left-16 margin-top-1 unselect"
           :class="{'svg-gray':filtered.total_page===filtered.cur_page,
           'svg-sub2':filtered.total_page!==filtered.cur_page}"
           src="/static/icon/fi_chevron-right-double.svg"
           style="width:20px;height:20px"
           @click="endPage"/>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      filtered: {
        type: Object
      }
    },
    data() {
      return {
        page_length:10,
        pagination: true,
        block: 1,
        start: 1,
        end: 1,
      }
    },
    watch: {
      filtered: {
        handler() {
          this.init()
        },
        deep: true
      }
    },
    created() {
    },
    computed: {
      calcPageList() {
        let page_list = [];
        for(let i=this.start; i<=this.end; i++) {
          page_list.push(i);
        }
        return page_list;
      }
    },
    methods: {
      init() {
        this.page_length = Number(this.filtered.page_length);
        this.block = Math.ceil(Number(this.filtered.cur_page) / this.page_length);
        this.start = (this.block * this.page_length) - (this.page_length - 1);

        this.end = Number(this.filtered.total_page);

        let max = this.block * this.page_length;
        if(this.end > max) {
          this.end = max;
        }


        /*if(this.filtered.page_num===1) {
          this.start = 1;
          this.block = 1;
        }
        if(this.start + 9 >= this.filtered.total_page) {
          this.end = this.filtered.total_page;
        }
        else if(this.block===1) {
          this.end = 10;
        }*/
      },
      changePage(num) {
        this.filtered.page_num = num;
        this.filtered.cur_page = num;
        this.check = false;
        this.$emit('getData');
      },
      prevPage() {
        if (this.filtered.page_num - this.page_length >= 1) {
          this.block -= 1;
          this.start = (this.block-1)*this.page_length+1;
          if(this.start <= 0) {
            this.start = 1;
          }
          this.end = this.start + (this.page_length - 1);
          this.filtered.page_num = this.start;
          this.filtered.cur_page = this.start;
          this.$emit('getData');
        }
      },
      nextPage() {
        if (this.filtered.page_num + this.page_length <= this.filtered.total_page) {
          this.block += 1;
          this.start = (this.block-1)*this.page_length+1;
          if(this.start + (this.page_length - 1) >= this.filtered.total_page) {
            this.end = this.filtered.total_page;
          }
          else {
            this.end = this.start + (this.page_length - 1);
          }
          this.filtered.page_num = this.start;
          this.filtered.cur_page = this.start;
          this.$emit('getData');
        }
        else {
          let next_block = this.block+1;
          if ((next_block - 1) * this.page_length + 1 <= this.filtered.total_page) {
            this.block += 1;
            this.start = (this.block - 1) * this.page_length + 1;
            this.end = this.filtered.total_page;
            this.filtered.page_num = this.start;
            this.filtered.cur_page = this.start;
            this.$emit('getData');
          }
        }
      },
      startPage() {
        this.block = 1;
        this.start = (this.block-1)*this.page_length + 1;
        if (this.filtered.total_page>this.page_length) this.end = this.start + (this.page_length - 1);
        else this.end = this.filtered.total_page;
        this.filtered.page_num = 1;
        this.filtered.cur_page = 1;
        this.$emit('getData');
      },
      endPage() {
        let block = Math.floor(this.filtered.total_page/this.page_length);
        let remainder = this.filtered.total_page % this.page_length;
        if(remainder > 0) block += 1;
        this.block = block;
        this.start = (this.block-1)*this.page_length+1;
        this.end = this.filtered.total_page;
        this.filtered.page_num = this.filtered.total_page;
        this.filtered.cur_page = this.filtered.total_page;
        this.$emit('getData');
      },
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pagination-group
    display flex
    align-items center
    justify-content center

  .pagination-num-selected
    background-color $gray4
    color $primary
    padding 4px 12px
    margin-left 6px
    margin-right 6px

  .pagination-num-not-selected
    margin-left 12px
    margin-right 12px

  .pagination-num-not-selected:hover
    color $sub2
    font-weight 500

</style>
